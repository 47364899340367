import React, { useRef, useLayoutEffect, useState, Fragment } from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import * as constants from '../constants';
import Layout from '../components/layout';
import SVG from 'react-inlinesvg';
import domToImage from 'dom-to-image';
import { useFullLogo } from '../hooks/logos';

import { useIcons } from '../hooks/icons';
import InstagramFrame from './../components/instagram-frame';
import SEO from '../components/seo';
import GlobalStyles from './../components/global-styles';
import { Details, Summary } from 'react-accessible-details';
import {
  mapLocation,
  AirtableIssueType,
  AirtableLocation,
  mapIssueType,
} from '../airtable-mapping';

import { urlToFile, shareImage } from './instagram-city';

const InstagramState: React.FC<{
  pageContext: {
    airtableLocation: AirtableLocation;
    airtableIssueTypes: AirtableIssueType[];
  };
}> = ({ pageContext }) => {
  const logo = useFullLogo();

  const icons = useIcons();

  const state = pageContext.airtableLocation
    ? mapLocation(pageContext.airtableLocation)
    : undefined;

  const allIssueTypes =
    pageContext.airtableIssueTypes
      ?.map(mapIssueType)
      .filter(type => !!type.name) ?? [];

  const currentIssueTypes =
    state?.contracts[0]?.issues.map(value => value.type.name.toString()) ?? [];

  const ref = useRef<HTMLDivElement>(null);

  const [imageURL, setImageURL] = useState<string>();
  const [imageFile, setImageFile] = useState<File>();

  useLayoutEffect(() => {
    if (ref && ref.current && document.getElementById('snapshot-container')) {
      setTimeout(() => {
        domToImage
          .toPng(document.getElementById('snapshot-container')!)
          .then(function (dataUrl) {
            urlToFile(
              imageURL!,
              `NixThe6-${state?.name}${
                state?.shortName ? '-' + state.shortName : ''
              }.png`,
              'image/png'
            ).then(function (file) {
              setImageURL(dataUrl);
              setImageFile(file);
              // shareImage(imageURL!, file);
            });
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          });
      }, 350);
    }
  }, [ref]);

  if (imageFile) {
    shareImage(
      imageURL!,
      imageFile!,
      `NixThe6-${state?.shortName ? '-' + state.shortName : ''}.png`
    );
  }

  const separators = [
    'verticalLine',
    'line',
    'verticalLine verticalLine2',
    'line line2',
    'verticalLine verticalLine3',
  ];
  return (
    <div ref={ref}>
      <InstagramFrame
        title={state?.name ?? ''}
        underline={
          <>
            Find your <strong>law enforcement bill of rights</strong>, <br />
            call your elected offices, and <em>demand change</em>.
          </>
        }
      >
        <Container>
          <ol>
            {Array.from({ length: 6 })
              .map((_, i) => (
                <Fragment>
                  <li
                    className={
                      currentIssueTypes.includes(allIssueTypes[i]?.name ?? '-')
                        ? ''
                        : 'dimmed'
                    }
                  >
                    <span className="number">{i + 1}</span>
                    {allIssueTypes[i]?.name ?? ''}
                  </li>
                </Fragment>
              ))
              .reduce(
                (acc, x, i) =>
                  acc === null ? (
                    x
                  ) : (
                    <>
                      {acc}
                      <div className={separators[i % separators.length]} />
                      {x}
                    </>
                  ),
                null
              )}
          </ol>
        </Container>
      </InstagramFrame>
    </div>
  );
};

export const Container = styled.div`
  width: 840px;
  ol {
    position: relative;
    list-style: none;
    counter-reset: i;
    border-top: 3px solid rgba(216, 216, 216, 0.1);
    border-bottom: 3px solid rgba(216, 216, 216, 0.1);
    padding: 0;
    margin: 0;
    margin-top: 24px;

    padding: 16px 0;

    display: grid;
    grid-template-columns: 420px auto;
    grid-gap: 32px;
    justify-items: start;
  }
  ol li {
    /* background: #f0f; */
    height: 120px;
    width: 420px;
    display: flex;
    position: relative;
    font-family: acumin-pro-wide;
    font-size: 22px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 26px;
    align-items: center;
    padding-right: 32px;
  }

  ol li .number {
    /* counter-increment: i;
           content: counter(i); */
    text-transform: uppercase;

    font-size: 44px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    font-family: VTCMarsha, acumin-pro-wide, sans-serif;
    font-weight: 700;
    padding-top: 4px;
    width: 72px;
    flex-grow: 0; /* do not grow   - initial value: 0 */
    flex-shrink: 0; /* do not shrink - initial value: 1 */
    flex-basis: 80px; /* width/height  - initial value: auto */
    margin: 0 24px 0 12px;
  }

  ol li::after {
    content: '';
    display: block;
    width: 80px;
    height: 80px;
    left: 12px;
    top: 20px;
    bottom: auto;
    width: 72px;
    height: 72px;
    /* background-color: #ff921e; */
    position: absolute;
    border-radius: 40px;

    border: 4px solid #ff921e;
  }

  .line {
    position: absolute;
    width: 840px;
    height: 2px;
    background: rgba(216, 216, 216, 0.1);
    top: 150px;
  }

  .line2 {
    top: 302px;
  }

  .verticalLine {
    position: absolute;
    width: 2px;
    height: 120px;
    background: rgba(216, 216, 216, 0.1);
    top: 16px;
    left: 419px;
  }

  .verticalLine2 {
    top: 168px;
  }
  .verticalLine3 {
    top: 320px;
  }
`;

export default InstagramState;
